import { loadTheme } from "@fluentui/react"
import { Stylesheet } from "@fluentui/react/lib/Styling"
import { merge } from "lodash"
import React from "react"
import ReactDOM from "react-dom"

import {
    registerDefaultApplicationComponents,
    ThemedApplication,
    translations as tconf
} from "@encoway/cui-application-components"
import {
    registerDefaultConfiguratorComponents,
    translations as tapp
} from "@encoway/cui-configurator-components"

import { getSettings } from "./settings"
import { getTheme } from "./theme"

declare global {
    interface Window {
        encoway: {
            baseUrl: string
            initialized: boolean
            startConfiguration: (productId: string, elementId: string) => void
        }
    }
}

window.encoway = {
    baseUrl: window.encoway.baseUrl || "",
    initialized: false,
    startConfiguration: (productId: string, elementId: string): void => {
        if (window.encoway.baseUrl === "") {
            console.error(
                "Configuration cannot be started! No base url is set!"
            )
            return
        }
        if (!window.encoway.initialized) {
            registerDefaultApplicationComponents()
            registerDefaultConfiguratorComponents()

            Stylesheet.getInstance().setConfig({ namespace: "encoway" })

            window.encoway.initialized = true
        }

        const container = document.getElementById(elementId)
        if (container !== null) {
            ReactDOM.unmountComponentAtNode(container)
        }

        ReactDOM.render(
            <ThemedApplication
                theme={loadTheme(getTheme())}
                translations={merge({}, tapp, tconf)}
                appSettings={getSettings(productId)}
            />,
            document.getElementById(elementId)
        )
    }
}
