export const getTheme = () => ({
    palette: {
        themePrimary: "#6ec800",
        themeLighterAlt: "#f8fdf3",
        themeLighter: "#e4f6cf",
        themeLight: "#ceeea7",
        themeTertiary: "#a2dd59",
        themeSecondary: "#7cce19",
        themeDarkAlt: "#62b300",
        themeDark: "#539700",
        themeDarker: "#3d6f00",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#9faabb",
        neutralSecondary: "#8591a5",
        neutralSecondaryAlt: "#8591a5",
        neutralPrimaryAlt: "#6c7990",
        neutralPrimary: "#1f2837",
        neutralDark: "#424e64",
        black: "#2f3b4e",
        white: "#ffffff"
    }
})
